import React, { useState } from 'react'
import {
  VStack,
  Button,
  Text,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { Hero3bg5 } from './index'
import emailjs from '@emailjs/browser'

export default function Hero4() {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
  })
  const toast = useToast()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: formData.name,
          from_email: formData.email,
          company: formData.company,
          message: formData.message,
          to_email: process.env.REACT_APP_EMAILJS_TO_EMAIL,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )

      toast({
        title: 'Request Sent',
        description: 'Your demo request has been submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })

      setFormData({ name: '', email: '', company: '', message: '' })
      setIsOpen(false)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to send request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <VStack
        w={{ base: '90%', '2xl': 'full' }}
        py={16}
        bgImage={Hero3bg5}
        bgSize='cover'
        bgPosition='center'
        borderRadius='12px'
        border='1px solid #F2ECFB'
        mx='auto'
        maxW='container.xl'
        id='get-started'
      >
        <VStack spacing={6} maxW='container.xl' textAlign='center'>
          <Text
            fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }}
            fontWeight='bold'
            color='#422678'
            lineHeight='1.2'
          >
            Get started with WhatCampaign today
          </Text>

          <Text fontSize={{ base: 'sm', lg: 'md' }} color='#384E6F' maxW='800px'>
            Start your marketing journey with our powerful campaign management platform. Streamline your workflow,
            engage your audience, and drive better results.
          </Text>

          <HStack spacing={4}>
            <Button
              as='a'
              href={'mailto:sales@whatcampaign.com'}
              colorScheme='purple'
              size='sm'
              fontWeight={500}
              px={5}
              py={3}
              rounded='4px'
            >
              Get In Touch
            </Button>
            <Button
              onClick={() => setIsOpen(true)}
              variant='outline'
              size='sm'
              fontWeight={500}
              px={5}
              py={3}
              borderColor='gray.300'
              bg='white'
              color='#4D5761'
              rounded='4px'
            >
              Request a Demo
            </Button>
          </HStack>
        </VStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color='purple.700'>Request a Demo</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder='Your name'
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    placeholder='Your company name'
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type='email'
                    placeholder='your.email@company.com'
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Message</FormLabel>
                  <Textarea
                    placeholder='Tell us about your needs...'
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                </FormControl>

                <Button
                  type='submit'
                  colorScheme='purple'
                  width='full'
                  mt={4}
                  isLoading={isLoading}
                  loadingText='Sending'
                >
                  Send Request
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
